import React, {
  Fragment,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Button,
  CodeSnippet,
  TextInput,
  Tile
} from '@carbon/react';

import {
  AddComment,
  MachineLearning,
  ArrowRight,
  ThumbsDown,
  ThumbsUp
} from '@carbon/icons-react';

import { useRemark } from 'react-remark';

import FeelEditorComponent from './FeelEditorComponent';
import JsonEditorComponent from './JsonEditorComponent';

import findXMLTag from './findXMLTag';

import isDebug from './isDebug';

const RATINGS = {
  GOOD: 5,
  BAD: 1
};

const EXAMPLES = [
  {
    prompt: 'What is FEEL?',
    context: '{}'
  },
  {
    prompt: 'List all items that will be out of stock soon (less than 10 items in stock).',
    context: `{
  "inventory": [
    {
      "name": "Hammer",
      "stock": 11
    },
    {
      "name": "Screwdriver",
      "stock": 2
    },
    {
      "name": "Wrench",
      "stock": 8
    }
  ]
}`
  },
  {
    prompt: 'Calculate the total working hours excluding break time.',
    context: `{
  "workingHours": "PT8H", 
  "breakTime": "PT1H"
}`
  }
];

export default function Chat(props) {
  const {
    context,
    isSubmitting,
    isWelcome,
    items,
    onContextChange,
    onSubmit,
    onSubmitFeedback,
    onSubmitRating
  } = props;

  const [ input, setInput ] = useState('');

  const [ feedback, setFeedback ] = useState('');
  const [ feedbackItemId, setFeedbackItemId ] = useState(null);
  const [ submittingFeedback, setSubmittingFeedback ] = useState(false);

  const submitPrompt = async (value, _context) => {
    if (!value.trim().length || isSubmitting) {
      return;
    }

    setInput('');

    const success = await onSubmit(value, _context || context);

    if (!success) {
      setInput(value);
    }
  };

  const onPromptKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitPrompt(input);
    }
  };

  const submitFeedback = async (value) => {
    if (!value.trim().length || submittingFeedback) {
      return;
    }

    setSubmittingFeedback(true);

    try {
      await onSubmitFeedback(feedbackItemId, value);
    } catch (error) {
      console.error('[App] error submitting feedback:', error);
    } finally {
      setFeedback('');
      setFeedbackItemId(null);
      setSubmittingFeedback(false);
    }
  };

  const onFeedbackKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitFeedback(feedback);
    } else if (event.key === 'Escape') {
      setFeedback('');
      setFeedbackItemId(null);
    }
  };

  const ref = useRef();

  useEffect(() => {

    // TODO: get rid of setTimeout
    setTimeout(() => {
      if (!ref.current) {
        return;
      }

      ref.current.scrollTop = ref.current.scrollHeight;

      const hasScroll = ref.current && ref.current.scrollHeight > ref.current.clientHeight;

      const margin = hasScroll ? (ref.current.offsetWidth - ref.current.clientWidth) : 0;

      ref.current.style.marginLeft = `${ margin }px`;
    }, 0);
  }, [ items ]);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onFeedback = (item) => {
    setFeedback('');
    setFeedbackItemId(item.id);
  };

  const onFeedbackReset = () => {
    setFeedback('');
    setFeedbackItemId(null);
  };

  return (
    <Fragment>
      <div className="chat">
        {
          !items.length && isWelcome && <div className="chat__welcome">
            <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" width="64" height="64" viewBox="0 0 32 32" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
              <rect x="5" y="7" width="22" height="22" fill="black" rx="4" ry="4"></rect>
              <path d="M16 25a6.9908 6.9908 0 01-5.833-3.1287l1.666-1.1074a5.0007 5.0007 0 008.334 0l1.666 1.1074A6.9908 6.9908 0 0116 25zM20 14a2 2 0 102 2A1.9806 1.9806 0 0020 14zM12 14a2 2 0 102 2A1.9806 1.9806 0 0012 14z" fill="#FD5F26"></path>
              <path d="M30,16V14H28V10a4.0045,4.0045,0,0,0-4-4H22V2H20V6H12V2H10V6H8a4.0045,4.0045,0,0,0-4,4v4H2v2H4v5H2v2H4v3a4.0045,4.0045,0,0,0,4,4H24a4.0045,4.0045,0,0,0,4-4V23h2V21H28V16ZM26,26a2.0023,2.0023,0,0,1-2,2H8a2.0023,2.0023,0,0,1-2-2V10A2.0023,2.0023,0,0,1,8,8H24a2.0023,2.0023,0,0,1,2,2Z" fill="black"></path>
              <rect x="5" y="10" width="22" height="10" fill="black"></rect>
              <circle cx="12" cy="16" r="2" fill="white" />
              <circle cx="20" cy="16" r="2" fill="white" />
            </svg>
            <br />
            <p>Ask me anything about FEEL (Friendly Enough Expression Language) or try one of the examples.</p>
            <div className="chat__welcome-examples">
              {
                EXAMPLES.map((example, index) => {
                  return <Tile key={ index } className="chat__welcome-example cds--tile--clickable" onClick={ () => submitPrompt(example.prompt, example.context) }>
                    { example.prompt }
                  </Tile>;
                })
              }
            </div>
          </div>
        }
        {
          items.length > 0 && <div ref={ ref } className="chat__messages">
            <div className="chat__messages-inner">
              {
                items.flatMap((item, index) => {
                  const {
                    id,
                    prompt,
                    response,
                    rating,
                    feedback: _feedback
                  } = item;

                  let messages = [
                    <div key={ `${ id }-user` } className="chat__message chat__message--user">
                      { prompt }
                    </div>
                  ];

                  if (response) {
                    messages = [
                      ...messages,
                      <div key={ `${ id }-assistant` } className="chat__message chat__message--assistant">
                        <div className="chat__message-icon">
                          <MachineLearning />
                        </div>
                        <div className="chat__message-content">
                          { getResponse(response, context) }
                          <div className="chat__message-actions">
                            <Button
                              className={ rating === RATINGS.GOOD ? 'rating rating--good rating--active' : 'rating rating--good' }
                              size="sm"
                              kind="ghost"
                              hasIconOnly
                              renderIcon={ rating === RATINGS.GOOD ? ThumbsUp : ThumbsUp }
                              iconDescription="Good"
                              onClick={ () => {
                                onSubmitRating(item, RATINGS.GOOD);
                                onFeedbackReset();
                              } } />
                            <Button
                              className={ rating === RATINGS.BAD ? 'rating rating--bad rating--active' : 'rating rating--bad' }
                              size="sm"
                              kind="ghost"
                              hasIconOnly
                              renderIcon={ rating === RATINGS.BAD ? ThumbsDown : ThumbsDown }
                              iconDescription="Bad"
                              onClick={ () => {
                                onSubmitRating(item, RATINGS.BAD);
                                onFeedbackReset();
                              } } />
                            {
                              rating && <Button
                                className={ feedbackItemId === item.id ? 'feedback feedback--active' : 'feedback' }
                                size="sm"
                                kind="ghost"
                                hasIconOnly
                                renderIcon={ AddComment }
                                iconDescription="Feedback"
                                onClick={ () => {
                                  if (feedbackItemId && feedbackItemId === item.id) {
                                    onFeedbackReset();
                                  } else {
                                    onFeedback(item);
                                  }
                                } } />
                            }
                          </div>
                          {
                            feedbackItemId === item.id && <div className="chat__message-feedback">
                              <TextInput
                                id="chat-feedback"
                                labelText="Feedback"
                                className="chat__message-feedback-text"
                                value={ feedback || _feedback || '' }
                                onChange={ (event) => setFeedback(event.target.value) }
                                onKeyDown={ onFeedbackKeyDown }
                                spellCheck={ false }
                                autoComplete="off"
                              />
                              <Button
                                className="chat__message-feedback-button"
                                renderIcon={ ArrowRight }
                                hasIconOnly
                                iconDescription="Submit"
                                disabled={ !feedback.trim().length || submittingFeedback }
                                onClick={ () => submitFeedback(feedback) }
                              />
                            </div>
                          }
                        </div>
                      </div>
                    ];
                  }

                  return messages;
                })
              }
              {
                isSubmitting && <div className="chat__message chat__message--assistant">
                  <div className="chat__message-icon chat__message-icon--pulse">
                    <MachineLearning />
                  </div>
                  <div className="chat__message-content">
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
      <div className="chat__input">
        <div className="chat__input-inner">
          <div className="chat__input-prompt">
            <TextInput
              id="chat-input"
              labelText=""
              ref={ inputRef }
              className="chat__input-text"
              value={ input }
              onChange={ (event) => setInput(event.target.value) }
              onKeyDown={ onPromptKeyDown }
              spellCheck={ false }
              autoComplete="off"
            />
            <Button
              className="chat__input-button"
              renderIcon={ ArrowRight }
              hasIconOnly
              iconDescription="Submit"
              disabled={ !input.trim().length || isSubmitting }
              onClick={ () => submitPrompt(input) }
            />
          </div>
          <JsonEditorComponent json={ context } onChange={ onContextChange } />
        </div>
      </div>
    </Fragment>
  );
}

const Markdown = (props) => {
  const { markdown } = props;

  const [ reactContent, setMarkdownSource ] = useRemark({
    rehypeReactOptions: {
      components: {
        code: (props) => {
          const {
            className,
            children
          } = props;


          const type = className ? 'multi' : 'inline';

          return <CodeSnippet type={ type } className={ className }>{ children }</CodeSnippet>;
        },

        // li: (props) => {
        //   const {
        //     children
        //   } = props;

        //   return <ListItem>{ children }</ListItem>;
        // },
        // ol: (props) => {
        //   const {
        //     children
        //   } = props;

        //   return <OrderedList>{ children.filter(child => child.$$typeof) }</OrderedList>;
        // },
        // ul: (props) => {
        //   const {
        //     children
        //   } = props;

        //   console.log(props);

        //   return <UnorderedList>{ children.filter(child => child.$$typeof) }</UnorderedList>;
        // }
      }
    }
  });

  useEffect(() => {
    setMarkdownSource(markdown);
  }, []);

  return reactContent;
};

/**
 * Get response. In case of an error, return the original response.
 *
 * @param {string} response
 * @param {object} context
 *
 * @returns {string}
 */
function getResponse(response, context) {
  const detailedSolution = findXMLTag(response, 'detailed_solution'),
        markdownText = findXMLTag(response, 'markdown_free_text');

  if (detailedSolution && markdownText) {
    return <Fragment>
      <FeelEditorComponent
        expression={ detailedSolution }
        context={ parseContext(context) }
        contextError={ hasParseContextError(context) }
        readOnly={ true } />
      <Markdown markdown={ markdownText } />
    </Fragment>;
  } else if (markdownText) {
    return <Markdown markdown={ markdownText } />;
  }

  isDebug() && console.error('[App] No detailed solution or free text found in response; this should never happen', response);

  return response;
}

function parseContext(context) {
  try {
    return JSON.parse(context);
  } catch (error) {
    isDebug() && console.error('[App] error parsing context:', error);

    return {};
  }
}

function hasParseContextError(context) {
  try {
    JSON.parse(context);
  } catch (error) {
    isDebug() && console.error('[App] error parsing context:', error);

    return true;
  }

  return false;
}